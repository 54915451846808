import React from 'react';
interface IProp {
  size?: number;
  className?: string;
}

const WithdrawalCompletedIcon: React.FC<IProp> = ({ size = 64, className }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.7733 40.4534C28.24 40.4534 27.7333 40.2401 27.36 39.8667L20.9067 33.4134C20.1333 32.6401 20.1333 31.3601 20.9067 30.5867C21.68 29.8134 22.96 29.8134 23.7333 30.5867L28.7733 35.6267L40.24 24.1601C41.0133 23.3867 42.2933 23.3867 43.0667 24.1601C43.84 24.9334 43.84 26.2134 43.0667 26.9867L30.1867 39.8667C29.8133 40.2401 29.3067 40.4534 28.7733 40.4534Z"
        fill="#9AC9E3"
      />
      <path
        d="M32 60.6668C30.32 60.6668 28.64 60.1068 27.3333 58.9868L23.12 55.3602C22.6933 54.9868 21.6267 54.6135 21.0667 54.6135H16.48C12.5333 54.6135 9.33335 51.4135 9.33335 47.4668V42.9068C9.33335 42.3468 8.96001 41.3068 8.58668 40.8802L4.98668 36.6402C2.80001 34.0535 2.80001 29.9735 4.98668 27.3868L8.58668 23.1468C8.96001 22.7202 9.33335 21.6802 9.33335 21.1202V16.5335C9.33335 12.5868 12.5333 9.38683 16.48 9.38683H21.0933C21.6533 9.38683 22.72 8.98683 23.1467 8.64016L27.36 5.0135C29.9733 2.7735 34.0533 2.7735 36.6667 5.0135L40.88 8.64016C41.3067 9.0135 42.3733 9.38683 42.9333 9.38683H47.4667C51.4133 9.38683 54.6133 12.5868 54.6133 16.5335V21.0668C54.6133 21.6268 55.0134 22.6935 55.3867 23.1202L59.0133 27.3335C61.2533 29.9468 61.2533 34.0268 59.0133 36.6402L55.3867 40.8535C55.0134 41.2802 54.6133 42.3468 54.6133 42.9068V47.4402C54.6133 51.3868 51.4133 54.5868 47.4667 54.5868H42.9333C42.3733 54.5868 41.3067 54.9868 40.88 55.3335L36.6667 58.9602C35.36 60.1068 33.68 60.6668 32 60.6668ZM16.48 13.3868C14.7467 13.3868 13.3333 14.8002 13.3333 16.5335V21.0935C13.3333 22.6135 12.6133 24.5602 11.6267 25.7068L8.02668 29.9468C7.09335 31.0402 7.09335 32.9335 8.02668 34.0268L11.6267 38.2668C12.6133 39.4402 13.3333 41.3602 13.3333 42.8802V47.4402C13.3333 49.1735 14.7467 50.5868 16.48 50.5868H21.0933C22.64 50.5868 24.5867 51.3068 25.76 52.3202L29.9733 55.9468C31.0667 56.8802 32.9867 56.8802 34.08 55.9468L38.2933 52.3202C39.4667 51.3335 41.4133 50.5868 42.96 50.5868H47.4933C49.2267 50.5868 50.64 49.1735 50.64 47.4402V42.9068C50.64 41.3602 51.36 39.4135 52.3733 38.2402L56 34.0268C56.9334 32.9335 56.9334 31.0135 56 29.9202L52.3733 25.7068C51.36 24.5335 50.64 22.5868 50.64 21.0402V16.5335C50.64 14.8002 49.2267 13.3868 47.4933 13.3868H42.96C41.4133 13.3868 39.4667 12.6668 38.2933 11.6535L34.08 8.02683C32.9867 7.0935 31.0667 7.0935 29.9733 8.02683L25.76 11.6802C24.5867 12.6668 22.6133 13.3868 21.0933 13.3868H16.48Z"
        fill="#9AC9E3"
      />
    </svg>
  );
};

export default WithdrawalCompletedIcon;
